










import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepIntroductionVideoViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-introduction-video-view-model';

@Component({
  name: 'FlagshipProfilingStepIntroductionVideo',
  components: {
    FlagshipProfilingVideo: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingVideo.vue'),
  },
})
export default class FlagshipProfilingStepIntroductionVideo extends Vue {
  flagship_introduction_video_view_model = Vue.observable(
    new FlagshipProfilingStepIntroductionVideoViewModel(this),
  );
}
